import React, { Component } from "react";
import { StepGeneral } from "../../components";
import { PathRouter } from "../../router";

export class FilesSettlement extends Component {
  render() {
    const { CONFIRMATION } = PathRouter;
    return (
      <StepGeneral
        informationText="Adjuntar foto de carta de despido y documentos entregados por la empresa"
        pathNextStep={CONFIRMATION}
        textButton="Continuar"
        classStyle="page vertical fileSettlement"
      />
    );
  }
}

export default FilesSettlement;
