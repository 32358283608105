import React, { Component } from "react";
import { StepGeneral, TextInput } from "../../components";
import { stepsNavigation } from "../../router";
import { AppContext } from "../../state";
import { validations, isValid } from "../../util";

export class PhoneNumberClient extends Component {
  render() {
    const { PHONE_NUMBER } = stepsNavigation;
    const {
      setPhoneNumberClient,
      phoneNumberClient,
      errorValidation,
      setError
    } = this.context;

    return (
      <StepGeneral
        classStyle="page vertical annualGrossSalary"
        informationText="Asesórate con un abogado laboralista"
        pathNavigation={PHONE_NUMBER}
        isValidToNextPath={() => {
          return isValid(phoneNumberClient, [validations.isRequired], setError);
        }}
        textButton="Contactar"
        styleButton="btn yellow upper"
      >
        <div className="input">
          <TextInput
            handleChangeText={setPhoneNumberClient}
            value={phoneNumberClient}
            type="number"
            error={errorValidation}
            placeholder="introduce tu teléfono"
          />
        </div>
      </StepGeneral>
    );
  }
}
PhoneNumberClient.contextType = AppContext;

export default PhoneNumberClient;
