import moment from "moment";
import { dismissalTypeEnum } from ".";
const SETTLEMENT_IS_ZERO = 0;
const NUMBER_OF_DAYS = 365;
const NUMBER_OF_MONTHS = 12;
const DATE_MAX_OLD_AGREEMENT = new Date("2012/2/12");

let dailySalary = 0;
let workedDays = 0;
let topMontlhyPayments = 0;
let annualGrossSalaryClient = 0;

export function calculateValueSettlement(dataSettlement, dismissalType) {
  const { annualGrossSalary, workStartDate, workFinishDate } = dataSettlement;

  calculateDailySalary(annualGrossSalary);
  calculateWorkedDays(workStartDate, workFinishDate);
  annualGrossSalaryClient = annualGrossSalary;
  if (dismissalType === dismissalTypeEnum.UFAIR) {
    return calculateSettlementToUnfairDismissal(dataSettlement);
  } else if (dismissalType === dismissalTypeEnum.OBJECTIVE) {
    return calculateSettlementToObjectiveDismissal();
  } else {
    return SETTLEMENT_IS_ZERO;
  }
}

function calculateSettlementToUnfairDismissal(dataSettlement) {
  const { workStartDate, workFinishDate } = dataSettlement;

  if (contractIsOnlyNewAgreement(workStartDate)) {
    return calculateSettlementInNewAgreement(workedDays);
  } else {
    const workedDaysInOldAgreement = calculateWorkedDaysInOldAgreement(
      workStartDate,
      workFinishDate
    );

    const workedDaysInNewAgreement = calculateWorkedDaysInNewAgreement(
      workFinishDate
    );

    let totalSettlement = calculateSettlementInOldAgreement(
      workedDaysInOldAgreement
    );

    if (workedDaysInNewAgreement > 0) {
      totalSettlement =
        totalSettlement +
        calculateSettlementInNewAgreement(workedDaysInNewAgreement);
    }
    return totalSettlement;
  }
}

function contractIsOnlyNewAgreement(workStartDate) {
  return workStartDate > DATE_MAX_OLD_AGREEMENT;
}

function calculateSettlementToObjectiveDismissal() {
  const daysCompensation = 20;
  topMontlhyPayments = 12;
  return generateSettlementAccordingToCompen(daysCompensation, workedDays);
}

function calculateSettlementInNewAgreement(workedDays) {
  const daysCompensation = 33;
  topMontlhyPayments = 24;
  return generateSettlementAccordingToCompen(daysCompensation, workedDays);
}
function calculateSettlementInOldAgreement(workedDays) {
  const daysCompensation = 45;
  topMontlhyPayments = 42;
  return generateSettlementAccordingToCompen(daysCompensation, workedDays);
}

function generateSettlementAccordingToCompen(daysCompensation, workedDays) {
  const compensationPercentege = daysCompensation / NUMBER_OF_MONTHS;
  const maxSettlementLegal = calculateMaxSettlementLegal();
  const settlement = dailySalary * workedDays * compensationPercentege;
  if (settlement > maxSettlementLegal) {
    return maxSettlementLegal;
  } else {
    return settlement;
  }
}

function calculateMaxSettlementLegal() {
  return (
    (Number(annualGrossSalaryClient) / NUMBER_OF_MONTHS) * topMontlhyPayments
  );
}

function calculateDailySalary(annualGrossSalary) {
  const salaryAsNumber = Number(annualGrossSalary);
  dailySalary = salaryAsNumber / NUMBER_OF_DAYS;
}

function calculateWorkedDays(workStartDate, workFinishDate) {
  workedDays = getDiffIntoDates(workStartDate, workFinishDate);
}

function calculateWorkedDaysInOldAgreement(workStartDate, workFinishDate) {
  if (workStartDate.getTime() === DATE_MAX_OLD_AGREEMENT.getTime()) {
    return 1;
  } else if (workFinishDate.getTime() < DATE_MAX_OLD_AGREEMENT.getTime()) {
    return getDiffIntoDates(workStartDate, workFinishDate);
  } else {
    return getDiffIntoDates(workStartDate, DATE_MAX_OLD_AGREEMENT);
  }
}

function calculateWorkedDaysInNewAgreement(workFinishDate) {
  if (workFinishDate.getTime() < DATE_MAX_OLD_AGREEMENT.getTime()) {
    return 0;
  }
  return getDiffIntoDates(DATE_MAX_OLD_AGREEMENT, workFinishDate);
}

function getDiffIntoDates(dateStart, dateFinish) {
  const dateStartMoment = moment(dateStart);
  const dateFinishMoment = moment(dateFinish);
  const monthsWorking = dateFinishMoment.diff(dateStartMoment, "months", true);

  if (Number.isInteger(monthsWorking)) {
    return monthsWorking + 1;
  }
  return Math.ceil(monthsWorking);
}
