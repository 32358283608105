import React, { Component } from "react";
import { StepGeneral } from "../components";
import { stepsNavigation } from "../router";

export class Confirmation extends Component {
  render() {
    const { CONFIRMATION } = stepsNavigation;

    return (
      <StepGeneral
        informationText="Gracias por contactar con elAbogado. En breve te llamamos."
        classStyle="page vertical pageConfirmation"
        pathNavigation={CONFIRMATION}
        textButton="Volver"
      />
    );
  }
}

export default Confirmation;
