import React, { Component } from "react";
import { StepGeneral, TextInput } from "../../components";
import { PathRouter } from "../../router";
import { AppContext } from "../../state";

export class Town extends Component {
  render() {
    const { DISMISSAL_TYPE } = PathRouter;
    const { setTown, town } = this.context;

    return (
      <StepGeneral
        informationText="¿En qué población estás?"
        pathNextStep={DISMISSAL_TYPE}
        classStyle="page vertical pageTown"
      >
        <div class="input">
          <TextInput handleChangeText={setTown} value={town} placeholder="Ejemplo: Madrid" />
         </div>
      </StepGeneral>
    );
  }
}
Town.contextType = AppContext;

export default Town;
