import React from "react";

const currentYear = new Date().getFullYear();

function YearMonthSelect({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths("es");

  const years = [];
  for (let i = 0; i <= 50; i += 1) {
    years.push(currentYear - i);
  }
  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
}

export default YearMonthSelect;
