import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { PageViewHandler } from "../components";

import { AppStateProvider } from "../state";
import { allPaths } from "./";

const AppRouter = () => {
  return (
    <Router>
      <AppStateProvider>
        {allPaths.map(router => {
          return (
            <Route
              exact
              path={router.path}
              component={router.component}
              key={router.path}
            />
          );
        })}
      </AppStateProvider>
      <PageViewHandler />
    </Router>
  );
};

export default AppRouter;
