import React, { Component } from "react";
import { StepGeneral, TimeClaim, TotalSettlement } from "../../components";
import { stepsNavigation } from "../../router";
import { AppContext } from "../../state";
import {
  calculateDateMaxClaim,
  calculateValueSettlement,
  dismissalTypeEnum,
  isValidDateMaxClaim,
} from "../../util/";
export class SummarySettlement extends Component {
  componentDidMount() {
    const { setUnfairCompensation, setObjectiveCompensation } = this.context;
    setUnfairCompensation(
      calculateValueSettlement(this.context, dismissalTypeEnum.UFAIR)
    );
    setObjectiveCompensation(
      calculateValueSettlement(this.context, dismissalTypeEnum.OBJECTIVE)
    );
  }

  getDateMaxToClaim(workFinishDate) {
    return calculateDateMaxClaim(workFinishDate);
  }
  getTextButton(dateMaxClaim) {
    if (isValidDateMaxClaim(dateMaxClaim)) {
      return "Asesórate con un abogado";
    }
    return "Contactar";
  }

  render() {
    const { SUMMARY_SETTLEMENT } = stepsNavigation;
    const {
      unfairCompensation,
      objectiveCompensation,
      workFinishDate,
      dismissalType,
    } = this.context;
    const dateMaxClaim = this.getDateMaxToClaim(workFinishDate).toString();
    const textToUser = isValidDateMaxClaim(
      this.getDateMaxToClaim(workFinishDate).toString()
    )
      ? dismissalType === dismissalTypeEnum.UFAIR
        ? "La empresa debería pagarte una indemnización de"
        : dismissalType === dismissalTypeEnum.OBJECTIVE
        ? "La empresa debería ofrecerte una indemnización de"
        : "Te han despedido sin indemnización, pero unos de nuestros abogados podría conseguir que se declare improcedente, con una indemnización de"
      : "";

    function FuncResult() {
      return (
        <div>
          {isValidDateMaxClaim(dateMaxClaim) ? (
            <>
              {dismissalType === dismissalTypeEnum.OBJECTIVE && (
                <>
                  <TotalSettlement
                    valueTotalSettlement={objectiveCompensation}
                  />
                  <div className="box h4">
                    Reclamando con uno de nuestros abogados podrías conseguir
                    que el despido se declare improcedente, con una
                    indemnización de
                  </div>
                </>
              )}
              <TotalSettlement valueTotalSettlement={unfairCompensation} />
            </>
          ) : (
            ""
          )}

          <TimeClaim date={dateMaxClaim} />
        </div>
      );
    }

    return (
      <StepGeneral
        informationText={textToUser}
        pathNavigation={SUMMARY_SETTLEMENT}
        textButton={this.getTextButton(dateMaxClaim)}
        classStyle="page vertical summarySettlement"
        goToElAbogado
        styleButton="btn yellow upper"
      >
        <FuncResult />
      </StepGeneral>
    );
  }
}
SummarySettlement.contextType = AppContext;

export default SummarySettlement;
