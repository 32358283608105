import React from "react";
import NumberFormat from "react-number-format";

function TotalSettlement(props) {
  const { valueTotalSettlement } = props;
  return (
    <p className="par totalSettlement">
      <span className="h1">
        <NumberFormat
          value={valueTotalSettlement}
          displayType={"text"}
          decimalSeparator=","
          thousandSeparator="."
        />
      </span>
      &nbsp;
      <span className="h2 currency">&euro;</span>
    </p>
  );
}

export default TotalSettlement;
