import React from "react";
import image from "../../assets/svg/sprite.svg";

export default function Header() {
  return (
    <header className="header">
      <div className="logo">
        <a title="Calcular Finiquito Despido" href="https://www.elabogado.com/">
          <svg className="svg logo-es">
            <title>elAbogado</title>
            <use xlinkHref={image + "#logo-es"} />
          </svg>
        </a>
      </div>
    </header>
  );
}
