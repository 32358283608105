import React, { Component } from "react";
import image from "../assets/svg/sapiens.svg";
import { PathRouter } from "../router";
import { AppContext } from "../state";

class Home extends Component {
  componentDidMount() {
    const { cleanContext } = this.context;
    cleanContext();
  }

  render() {
    const { history } = this.props;
    const { START_DATE_WORK } = PathRouter;
    return (
      <div className="home page">
        <div className="wrap">
        <h1 className="title">Calcular mi finiquito por despido</h1>
          <img
            alt="Usuario utilizando la hierramienta"
            className="img"
            src={image}
          />
          <button
            onClick={() => {
              history.push(START_DATE_WORK);
            }}
            className="btn yellow upper"
          >
            Calcular ahora
          </button>
          <div className="privacy smallest">
            <a
              className="lnk"
              href="https://www.elabogado.com/static/static_privacyPolicy"
              target="_blank"
              title="Política de Privacidad"
              rel="noopener noreferrer"
            >
              Política de Privacidad
            </a>
            {" y "}
            <a
              className="lnk"
              href="https://www.elabogado.com/condiciones-uso/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Condiciones de Uso
            </a>
          </div>
        </div>
      </div>
    );
  }
}
Home.contextType = AppContext;

export default Home;
