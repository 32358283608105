import React, { Component } from "react";
import { StepGeneral, DateInput } from "../../components";
import { stepsNavigation } from "../../router";
import { AppContext } from "../../state";
import { validations, isValid } from "../../util";

export class StartDateWork extends Component {
  render() {
    const { START_DATE_WORK } = stepsNavigation;
    const {
      setWorkStartDate,
      workStartDate,
      setError,
      errorValidation
    } = this.context;

    return (
      <StepGeneral
        informationText="¿Cuál es la fecha de inicio de tu contrato?"
        pathNavigation={START_DATE_WORK}
        classStyle="page vertical startDateWork"
        isValidToNextPath={() => {
          return isValid(workStartDate, [validations.isRequired], setError);
        }}
      >
        <DateInput
          handleDayChange={setWorkStartDate}
          value={workStartDate}
          error={errorValidation}
        />
      </StepGeneral>
    );
  }
}
StartDateWork.contextType = AppContext;

export default StartDateWork;
