import React, { Component } from "react";
import { StepGeneral, TextInput } from "../../components";
import { PathRouter } from "../../router";
import { AppContext } from "../../state";

export class NameClient extends Component {
  render() {
    const { FILE_SETTLEMENT } = PathRouter;
    const { setNameClient, nameClient } = this.context;

    return (
      <StepGeneral
        informationText="¿Cómo te llamas?"
        pathNextStep={FILE_SETTLEMENT}
        classStyle="page vertical pageName"
      >
        <div class="input">
          <TextInput
            value={nameClient}
            handleChangeText={setNameClient}
            placeholder="Tu nombre"
          />
        </div>
      </StepGeneral>
    );
  }
}

NameClient.contextType = AppContext;

export default NameClient;
