import PropTypes from "prop-types";
import React from "react";
import { formatDate, isValidDateMaxClaim } from "../../util";

function TimeClaim(props) {
  const countDown = props;
  return (
    <div className="countdown">
      {isValidDateMaxClaim(countDown.date) ? (
        <div>
          <span className="span1">
            Tienes un plazo límite para reclamar hasta el
          </span>
          <br />
          <span className="countdown-col-element">
            <strong>{`${formatDate(countDown.date)} `}</strong>
          </span>
          <br />
        </div>
      ) : (
        <span className="span3">
          Podrías estar fuera de plazo para reclamar. Por las dudas, contacta
          con un abogado para verificarlo.
        </span>
      )}
    </div>
  );
}

TimeClaim.propTypes = {
  date: PropTypes.string.isRequired,
};

TimeClaim.defaultProps = {
  date: new Date().setDate(new Date().getDate() - 2),
};

export default TimeClaim;
