import PropTypes from "prop-types";
import React, { useContext } from "react";
import { __RouterContext } from "react-router-dom";
import image from "../../assets/svg/sprite.svg";
import { PathRouter } from "../../router";
import { pushEvent } from "../../util";
function StepGeneral(props) {
  const {
    informationText,
    textButton,
    children,
    pathNavigation,
    classStyle,
    withNextButton,
    isValidToNextPath,
    goToElAbogado,
    styleButton
  } = props;

  const { history } = useContext(__RouterContext);

  if (history.action === "POP") {
    history.push(PathRouter.HOME);
  }

  const goNextStep = () => {
    if (goToElAbogado) {
      pushEvent({ category: "User", action: `Clicked ${textButton}` });
      window.location.href = pathNavigation.to;
    } else if (isValidToNextPath()) {
      history.push(pathNavigation.to);
    }
  };
  const goPreviousStep = () => {
    history.push(pathNavigation.from);
  };

  return (
    <div className={classStyle}>
      <button className="back" onClick={goPreviousStep}>
        <svg className="svg ico-arrow">
          <use xlinkHref={image + "#ico-arrow"} />
        </svg>
      </button>
      <h1 className="title h2">{informationText}</h1>
      <div className="box">{children}</div>
      {goToElAbogado ? 
      (<form action={pathNavigation.to} method="POST">
        <input type="hidden" name="message.assignationEnabled" value="true"/>
        <input type="hidden" name="message.trace.source" id="messageSource-lead" value="CALCULATOR"/>
        <button className={styleButton} type="submit">
          {textButton}
        </button>
        </form>) : withNextButton && (
        <button className={styleButton} onClick={goNextStep}>
          {textButton}
        </button>
      )}
    </div>
  );
}

StepGeneral.defaultProps = {
  textButton: "Siguiente",
  withNextButton: true,
  isValidToNextPath: () => true,
  goToElAbogado: false,
  styleButton: "btn white upper"
};

StepGeneral.propTypes = {
  informationText: PropTypes.string.isRequired,
  textButton: PropTypes.string,
  pathNavigation: PropTypes.object
};

export default StepGeneral;
