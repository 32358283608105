import {
  AnnualGrossSalary,
  Confirmation,
  DismissalType,
  EndDateWork,
  FilesSettlement,
  Home,
  NameClient,
  NotSettlement,
  NumberAnnualPayments,
  OtherNumberAnnualPayments,
  PhoneNumberClient,
  StartDateWork,
  SummarySettlement,
  Town
} from "../pages";

const paths = {
  HOME: "/",
  START_DATE_WORK: "/start_date_work",
  FINAL_DATE_WORK: "/final_date_work",
  SALARY: "/salary",
  NUMBER_ANNUAL_PAYMENTS: "/number_annual_payments",
  TOWN: "/town",
  DISMISSAL_TYPE: "/dismissal_type",
  SUMMARY_SETTLEMENT: "/summary_settlement",
  PHONE_NUMBER: "/phone_number",
  NAME_CLIENT: "/name",
  FILE_SETTLEMENT: "/files_settlement",
  COMPLETE_NUM_ANNUAL_PAYMENTS: "/complete_number_annual_payments",
  NOT_SETTLEMENT: "/not_settlement",
  CONFIRMATION: "/done"
};

export const stepsNavigation = {
  HOME: { from: paths.HOME, to: paths.START_DATE_WORK },
  START_DATE_WORK: { from: paths.HOME, to: paths.FINAL_DATE_WORK },
  FINAL_DATE_WORK: { from: paths.START_DATE_WORK, to: paths.SALARY },
  SALARY: { from: paths.FINAL_DATE_WORK, to: paths.DISMISSAL_TYPE },
  NUMBER_ANNUAL_PAYMENTS: {
    from: paths.SALARY,
    to: paths.DISMISSAL_TYPE
  },
  OTHER_NUMBER_ANNUAL_PAYMENTS: {
    from: paths.NUMBER_ANNUAL_PAYMENTS,
    to: paths.DISMISSAL_TYPE
  },
  DISMISSAL_TYPE: {
    from: paths.SALARY,
    to: paths.SUMMARY_SETTLEMENT
  },
  SUMMARY_SETTLEMENT: {
    from: paths.DISMISSAL_TYPE,
    to: "https://www.elabogado.com/message/create/phone"
  },
  PHONE_NUMBER: {
    from: paths.SUMMARY_SETTLEMENT,
    to: paths.CONFIRMATION
  },
  NOT_SETTLEMENT: {
    from: paths.DISMISSAL_TYPE,
    to: "https://www.elabogado.com/message/create/phone"
  },
  CONFIRMATION: {
    from: paths.PHONE_NUMBER,
    to: paths.HOME
  }
};

export const allPaths = [
  { path: paths.HOME, component: Home },
  { path: paths.START_DATE_WORK, component: StartDateWork },
  { path: paths.FINAL_DATE_WORK, component: EndDateWork },
  { path: paths.SALARY, component: AnnualGrossSalary },
  { path: paths.NUMBER_ANNUAL_PAYMENTS, component: NumberAnnualPayments },
  { path: paths.TOWN, component: Town },
  { path: paths.DISMISSAL_TYPE, component: DismissalType },
  { path: paths.SUMMARY_SETTLEMENT, component: SummarySettlement },
  { path: paths.PHONE_NUMBER, component: PhoneNumberClient },
  { path: paths.NAME_CLIENT, component: NameClient },
  { path: paths.FILE_SETTLEMENT, component: FilesSettlement },
  { path: paths.CONFIRMATION, component: Confirmation },
  {
    path: paths.COMPLETE_NUM_ANNUAL_PAYMENTS,
    component: OtherNumberAnnualPayments
  },
  {
    path: paths.NOT_SETTLEMENT,
    component: NotSettlement
  }
];

export default paths;
