import React, { Component } from "react";
import { StepGeneral } from "../../components";
import { PathRouter, stepsNavigation } from "../../router";
import { AppContext } from "../../state";

export class NumberAnnualPayments extends Component {
  render() {
    const { history } = this.props;
    const { COMPLETE_NUM_ANNUAL_PAYMENTS, DISMISSAL_TYPE } = PathRouter;
    const { NUMBER_ANNUAL_PAYMENTS } = stepsNavigation;
    const { setNumberAnnualPayments } = this.context;

    const onClickButton = e => {
      const value = e.target.value;
      if (value === "otras") {
        history.push(COMPLETE_NUM_ANNUAL_PAYMENTS);
      } else {
        setNumberAnnualPayments(value);
        history.push(DISMISSAL_TYPE);
      }
    };

    return (
      <StepGeneral
        informationText="Números de pagas que tienes al año"
        withNextButton={false}
        classStyle="page vertical numberAnnualPayments"
        pathNavigation={NUMBER_ANNUAL_PAYMENTS}
      >
        <div className="btn-list">
          <button className="btn white" onClick={onClickButton} value="12">
            12
          </button>
          <button className="btn white" onClick={onClickButton} value="14">
            14
          </button>
          <button className="btn white" onClick={onClickButton} value="otras">
            Otro número
          </button>
        </div>
      </StepGeneral>
    );
  }
}
NumberAnnualPayments.contextType = AppContext;
export default NumberAnnualPayments;
