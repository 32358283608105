let funcSetError = () => {};

export const validations = {
  isRequired: "isRequired",
  email: "email"
};

export const isValid = (valueField, fieldValidations, setError) => {
  let fielIsValid = true;
  funcSetError = setError;
  fieldValidations.forEach(element => {
    if (element === validations.isRequired) {
      if (!valueField) {
        fielIsValid = setErrorInContext("Debes ingresar un valor");
      }
    } else if (element === validations.email) {
      if (!emailIsValid(valueField)) {
        fielIsValid = setErrorInContext("El email no es valido");
      }
    }
  });

  if (fielIsValid) {
    cleanErrorInContext();
  }
  return fielIsValid;
};

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function setErrorInContext(errorText) {
  funcSetError({
    isError: true,
    errorText
  });
  return false;
}

function cleanErrorInContext() {
  funcSetError({
    isError: false,
    erroText: ""
  });
  return true;
}
