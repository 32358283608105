import React, { Component } from "react";
import { withRouter } from "react-router";
import { AppContext } from "./";

const defaultState = {
  workStartDate: "",
  workFinishDate: "",
  annualGrossSalary: "",
  numberAnnualPayments: "",
  town: "",
  dismissalType: "",
  phoneNumberClient: "",
  nameClient: "",
  totalSettlement: 0,
  unfairCompensation: 0,
  objectiveCompensation: 0,
  errorValidation: {
    isError: false,
    error: ""
  }
};

class AppStateProvider extends Component {
  setWorkStartDate = newStartDate => {
    this.setState({ workStartDate: newStartDate });
  };
  setWorkFinishDate = newFinishDate => {
    this.setState({ workFinishDate: newFinishDate });
  };
  setAnnualGrossSalary = newAnnualSalary => {
    this.setState({ annualGrossSalary: newAnnualSalary });
  };
  setNumberAnnualPayments = newNumberPayments => {
    this.setState({ numberAnnualPayments: newNumberPayments });
  };
  setTown = newtown => {
    this.setState({ town: newtown });
  };
  setDismissalType = newSismissalType => {
    this.setState({ dismissalType: newSismissalType });
  };
  setPhoneNumberClient = newPhoneNumber => {
    this.setState({ phoneNumberClient: newPhoneNumber });
  };
  setNameClient = newName => {
    this.setState({ nameClient: newName });
  };
  setTotalSettlement = newSettlement => {
    this.setState({ totalSettlement: parseInt(newSettlement, 10) });
  };
  setUnfairCompensation = newCompensation => {
    this.setState({ unfairCompensation: parseInt(newCompensation, 10) });
  };
  setObjectiveCompensation = newCompensation => {
    this.setState({ objectiveCompensation: parseInt(newCompensation, 10) });
  };
  setGoTo = newFunct => {
    this.setState({ goTo: newFunct });
  };
  setError = newError => {
    this.setState({ errorValidation: newError });
  };

  cleanContext = () => {
    this.setState({ ...defaultState });
  };
  state = {
    ...defaultState,
    goTo: () => {},
    setWorkStartDate: this.setWorkStartDate,
    setWorkFinishDate: this.setWorkFinishDate,
    setAnnualGrossSalary: this.setAnnualGrossSalary,
    setNumberAnnualPayments: this.setNumberAnnualPayments,
    setTown: this.setTown,
    setDismissalType: this.setDismissalType,
    setPhoneNumberClient: this.setPhoneNumberClient,
    setNameClient: this.setNameClient,
    setTotalSettlement: this.setTotalSettlement,
    setUnfairCompensation: this.setUnfairCompensation,
    setObjectiveCompensation: this.setObjectiveCompensation,
    setGoTo: this.setGoTo,
    setError: this.setError,
    cleanContext: this.cleanContext
  };
  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default withRouter(AppStateProvider);
