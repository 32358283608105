import React from "react";
import { AppRouter } from "../router";
import { Header, Body } from "../components";
function App() {
  return (
    <div className="main">
      <Header />
      <Body>
        <AppRouter />
      </Body>
    </div>
  );
}

export default App;
