import React from "react";
import PropTypes from "prop-types";

function TextInput(props) {
  const {
    handleChangeText,
    value,
    type,
    placeholder,
    error: { isError, errorText }
  } = props;

  return (
    <div className="inputElem">
      <input
        value={value}
        onChange={e => {
          handleChangeText(e.target.value);
        }}
        type={type}
        placeholder={placeholder}
      />
      {isError && <div className="error">{errorText}</div>}
    </div>
  );
}

TextInput.defaultProps = {
  error: {
    isError: false,
    errorText: ""
  },
  type: "text"
};

TextInput.propTypes = {
  handleChangeText: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default TextInput;
