import React, { Component } from "react";
import { StepGeneral, DateInput } from "../../components";
import { stepsNavigation } from "../../router";
import { AppContext } from "../../state";
import { validations, isValid } from "../../util";

export class EndDateWork extends Component {
  render() {
    const { FINAL_DATE_WORK } = stepsNavigation;
    const {
      setWorkFinishDate,
      workFinishDate,
      workStartDate,
      setError,
      errorValidation
    } = this.context;

    return (
      <StepGeneral
        informationText="¿Cuál es la fecha del despido?"
        pathNavigation={FINAL_DATE_WORK}
        classStyle="page vertical finalDateWork"
        isValidToNextPath={() => {
          return isValid(workFinishDate, [validations.isRequired], setError);
        }}
      >
        <DateInput
          handleDayChange={setWorkFinishDate}
          value={workFinishDate}
          notSelectedBeforeTo={workStartDate}
          error={errorValidation}
        />
      </StepGeneral>
    );
  }
}
EndDateWork.contextType = AppContext;

export default EndDateWork;
