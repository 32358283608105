import ReactGA from "react-ga";

ReactGA.initialize("UA-37468552-13");

export function pushNewPageView(pageName) {
  ReactGA.pageview(pageName);
}
export function pushEvent(objectEvent) {
  ReactGA.event(objectEvent);
}
