import React from "react";
import { StepGeneral } from "../../components";
import { stepsNavigation } from "../../router";

function NotSettlement() {
  const { NOT_SETTLEMENT } = stepsNavigation;
  return (
    <StepGeneral
      informationText="En caso de despido disciplinario no hay indemnización."
      classStyle="page vertical notSettlement"
      pathNavigation={NOT_SETTLEMENT}
      textButton="Contactar"
      goToElAbogado
      styleButton="btn yellow upper"
    >
      <p className="par">
        Si necesitas un abogado laboralista, contacta con elAbogado
      </p>
    </StepGeneral>
  );
}

export default NotSettlement;
