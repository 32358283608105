import React, { Component } from "react";
import { StepGeneral, TextInput } from "../../components";
import { stepsNavigation } from "../../router";
import { AppContext } from "../../state";
import { validations, isValid } from "../../util";

class AnnualGrossSalary extends Component {
  render() {
    const { SALARY } = stepsNavigation;
    const {
      annualGrossSalary,
      setAnnualGrossSalary,
      errorValidation,
      setError
    } = this.context;
    return (
      <StepGeneral
        classStyle="page vertical annualGrossSalary"
        informationText="¿Cuál es tu salario bruto anual?"
        pathNavigation={SALARY}
        isValidToNextPath={() => {
          return isValid(annualGrossSalary, [validations.isRequired], setError);
        }}
      >
        <div className="input">
          <TextInput
            handleChangeText={setAnnualGrossSalary}
            value={annualGrossSalary}
            type="number"
            error={errorValidation}
            placeholder="Ejemplo: 25000"
          />
        </div>
      </StepGeneral>
    );
  }
}

AnnualGrossSalary.contextType = AppContext;
export default AnnualGrossSalary;
