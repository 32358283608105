import "moment/locale/es";
import PropTypes from "prop-types";
import React from "react";
import DayPicker from "react-day-picker/DayPicker";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils from "react-day-picker/moment";
import YearMonthSelect from "./YearMonthSelect";

function DateInput(props) {
  const {
    handleDayChange,
    value,
    notSelectedBeforeTo,
    error: { isError, errorText }
  } = props;

  const handleDateChange = (date, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    if (notSelectedBeforeTo === undefined) {
      handleDayChange(date);
    } else if (date > notSelectedBeforeTo) {
      handleDayChange(date);
    }
  };

  const handleDateMonthProp = () => {
    return !value ? new Date() : value;
  };

  const today = new Date();

  return (
    <div className="input">
      <DayPicker
        selectedDays={!value ? null : value}
        fixedWeeks
        month={handleDateMonthProp()}
        onDayClick={handleDateChange}
        locale="es"
        localeUtils={MomentLocaleUtils}
        captionElement={({ date, localeUtils }) => (
          <YearMonthSelect
            date={date}
            localeUtils={localeUtils}
            onChange={handleDateChange}
          />
        )}
        modifiers={{ disabled: { before: notSelectedBeforeTo, after: today } }}
      />
      {isError && <div className="error">{errorText}</div>}
    </div>
  );
}

DateInput.defaultProps = {
  error: {
    isError: false,
    errorText: ""
  }
};

DateInput.propTypes = {
  handleDayChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired
};

export default DateInput;
