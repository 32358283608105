import React, { useContext } from "react";
import { __RouterContext } from "react-router-dom";
import { pushNewPageView } from "../../util";

function PageViewHandler() {
  const { history } = useContext(__RouterContext);

  function onNewPageView() {
    const { location } = history;
    pushNewPageView(location.pathname);
  }

  return <div id="gaAnalitycs">{onNewPageView()}</div>;
}

export default PageViewHandler;
