import React, { Component } from "react";
import { StepGeneral } from "../../components";
import { PathRouter, stepsNavigation } from "../../router";
import { AppContext } from "../../state";
import { dismissalTypeEnum } from "../../util";

export class DismissalType extends Component {
  render() {
    const { history } = this.props;
    const { SUMMARY_SETTLEMENT, NOT_SETTLEMENT } = PathRouter;
    const { DISMISSAL_TYPE } = stepsNavigation;
    const { setDismissalType } = this.context;
    const onClickButton = (e) => {
      const dismissalTypeSelected = e.target.value;
      setDismissalType(dismissalTypeSelected);
      if (dismissalTypeSelected === dismissalTypeEnum.DISCIPLINARY_DISMISSAL) {
        history.push(NOT_SETTLEMENT);
      } else {
        history.push(SUMMARY_SETTLEMENT);
      }
    };

    return (
      <StepGeneral
        informationText="Tipo de despido"
        withNextButton={false}
        classStyle="page vertical dismissalType"
        pathNavigation={DISMISSAL_TYPE}
      >
        <div className="btn-list">
          <button
            className="btn white"
            onClick={onClickButton}
            value={dismissalTypeEnum.UFAIR}
          >
            Despido Improcedente
          </button>
          <button
            className="btn white"
            onClick={onClickButton}
            value={dismissalTypeEnum.OBJECTIVE}
          >
            Despido Objetivo Procedente
          </button>
          <button
            className="btn white"
            value={dismissalTypeEnum.DISCIPLINARY}
            onClick={onClickButton}
          >
            Despido Disciplinario
          </button>
        </div>
      </StepGeneral>
    );
  }
}
DismissalType.contextType = AppContext;

export default DismissalType;
