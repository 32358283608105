import moment from "moment";

export function formatDate(date) {
  const dateMaxClaim = moment(date);
  return dateMaxClaim.format("DD-MM-YYYY");
}

export function calculateDateMaxClaim(dateFinishWork) {
  const dateMaxClaim = moment(dateFinishWork);
  let count = 0;
  let day = 0;
  do {
    let dateToEvaluate = dateMaxClaim.clone();
    dateToEvaluate.add(day, "day");
    if (dateToEvaluate.day() !== 6 && dateToEvaluate.day() !== 0) {
      count = count + 1;
    }
    if (count < 20) {
      day++;
    }
  } while (count < 20);
  dateMaxClaim.add(day, "day");
  return dateMaxClaim;
}

export function isValidDateMaxClaim(dateMaxClaim) {
  return new Date(dateMaxClaim) > new Date();
}
