import React, { Component } from "react";
import { StepGeneral, TextInput } from "../../components";
import { stepsNavigation } from "../../router";
import { AppContext } from "../../state";
import { validations, isValid } from "../../util";

class OtherNumberAnnualPayments extends Component {
  render() {
    const { OTHER_NUMBER_ANNUAL_PAYMENTS } = stepsNavigation;
    const {
      setNumberAnnualPayments,
      numberAnnualPayments,
      errorValidation,
      setError
    } = this.context;

    return (
      <StepGeneral
        informationText="¿Cuántas pagas tienes al año?"
        pathNavigation={OTHER_NUMBER_ANNUAL_PAYMENTS}
        classStyle="page vertical otherAnnualPayments"
        isValidToNextPath={() => {
          return isValid(
            numberAnnualPayments,
            [validations.isRequired],
            setError
          );
        }}
      >
        <div className="input">
          <TextInput
            value={numberAnnualPayments}
            handleChangeText={setNumberAnnualPayments}
            type="number"
            placeholder="Ejemplo: 6"
            error={errorValidation}
          />
        </div>
      </StepGeneral>
    );
  }
}

OtherNumberAnnualPayments.contextType = AppContext;

export default OtherNumberAnnualPayments;
